import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const marketingRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.marketingCampaigns,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.website,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.marketingContacts,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.emailTemplates,
    async () => await import('@/pages/UnimplementedPage'),
  ),
]
