import { type FirebaseError } from 'firebase/app'
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  type User,
  signOut as firebaseSignOut,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'

import { firebaseAuth } from '@/configs/firebase'

export const googleAuthProvider = new GoogleAuthProvider()

export async function signInWithEmail(email: string, password: string) {
  await signInWithEmailAndPassword(firebaseAuth, email, password)
}

export async function signInWithGoogle() {
  await signInWithPopup(firebaseAuth, googleAuthProvider)
}

export async function signOut() {
  await firebaseSignOut(firebaseAuth)
  await refreshAuthToken()
}

export async function reauthenticateWithPassword(
  user: User | null | undefined,
  password: string | null | undefined,
) {
  if (user == null) {
    return false
  }

  const passwordProvider = user.providerData.find(
    ({ providerId }) => providerId === 'password',
  )

  if (passwordProvider == null) {
    return true
  }

  if (passwordProvider.email == null || password == null) {
    return false
  }

  try {
    const credential = EmailAuthProvider.credential(
      passwordProvider.email,
      password,
    )
    await reauthenticateWithCredential(user, credential)
  } catch {
    return false
  }
  return true
}

export async function refreshAuthToken() {
  await firebaseAuth.currentUser?.getIdToken(true)
}

export function firebaseErrorToMessage(error: FirebaseError): string {
  switch (error.code) {
    case 'auth/wrong-password':
    case 'auth/user-not-found':
    case 'auth/invalid-credential':
      return 'Invalid email/password combination'
    case 'auth/user-disabled':
      return 'This account has been disabled'
    default:
      return error.message
  }
}
