import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link'
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom'

export type LinkProps = MuiLinkProps & RouterLinkProps

export function Link(props: LinkProps) {
  return <MuiLink component={RouterLink} role="link" {...props} />
}
