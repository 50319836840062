import Card, { type CardProps } from '@mui/material/Card'
import CardContent, { type CardContentProps } from '@mui/material/CardContent'
import CardHeader, { type CardHeaderProps } from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { type Ref, forwardRef } from 'react'

import { useThemeConfig } from '@/providers/ThemeConfigProvider/useThemeConfig'

const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
}

export interface MainCardProps {
  border?: boolean
  boxShadow?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  contentClass?: string
  contentSX?: CardContentProps['sx']
  darkTitle?: boolean
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: React.ReactNode | string
}

export const MainCard = forwardRef(
  (
    {
      border = false,
      boxShadow = false,
      children,
      contentClass = '',
      contentSX = {},
      darkTitle = false,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { mode } = useThemeConfig()

    const defaultShadow =
      mode === 'dark'
        ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
        : '0 2px 14px 0 rgb(32 40 45 / 8%)'

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          'border': border ? '1px solid' : 'none',
          'borderColor': 'divider',
          ':hover': {
            boxShadow: boxShadow ? (shadow ?? defaultShadow) : 'inherit',
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title != null && (
          <CardHeader sx={headerSX} title={title} action={secondary} />
        )}
        {darkTitle && title != null && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h3">{title}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title != null && children != null && <Divider />}

        {/* card content */}
        {children != null && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
      </Card>
    )
  },
)
