import { type RouteObject } from 'react-router-dom'

import { agencyRoutes } from './agencies'
import { agentsRoutes } from './agents'
import { customersRoutes } from './customers'
import { invitationsRoutes } from './invitations'

export const contactsRoutes: RouteObject[] = [
  ...agencyRoutes,
  ...agentsRoutes,
  ...customersRoutes,
  ...invitationsRoutes,
]
