import { type Decimal } from 'decimal.js'

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function centsToFormattedCurrency(cents: number | Decimal): string {
  const dollars = typeof cents === 'number' ? cents / 100 : cents.dividedBy(100)
  return dollarsToFormattedCurrency(dollars)
}

export function dollarsToFormattedCurrency(dollars: number | Decimal): string {
  const parsedDollars =
    typeof dollars === 'number' ? dollars : dollars.toNumber()
  return currencyFormat.format(parsedDollars)
}
