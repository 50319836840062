import { type ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { AuthRedirector } from './AuthRedirector'

import { type AuthenticationContextValue } from '@/providers/AuthenticationProvider/types'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export interface AuthProtectedRouteProps {
  allowIf?: (authentication: AuthenticationContextValue) => boolean
  onReject?: ReactNode
}

export function AuthProtectedRoute({
  allowIf = () => true,
  onReject = <AuthRedirector />,
}: AuthProtectedRouteProps) {
  const auth = useAuthentication()
  const allowed = allowIf(auth)

  return allowed ? <Outlet /> : onReject
}
