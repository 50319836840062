import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from './helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { BasicLayout } from '@/layouts'

export const otherRoutes: RouteObject[] = [
  {
    element: <BasicLayout />,
    children: [
      createRoleProtectedRouteObject(
        routes.termsAndConditions,
        async () => await import('@/pages/other/TermsAndConditionsPage'),
      ),
      createRoleProtectedRouteObject(
        routes.privacyPolicy,
        async () => await import('@/pages/other/PrivacyPolicyPage'),
      ),
    ],
  },
  {
    path: '/embedded-lead-form',
    lazy: async () => await import('@/pages/other/EmbeddedLeadFormPage'),
  },
]
