import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const customersRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.customers,
    async () => await import('@/pages/contacts/CustomersPage'),
  ),
  createRoleProtectedRouteObject(
    routes.customerDetails,
    async () => await import('@/pages/contacts/CustomerDetailsPage'),
  ),
]
