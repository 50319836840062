import Stack, { type StackProps } from '@mui/material/Stack'
import { type Except } from 'type-fest'

import { Image } from './Image'

const sizes = {
  small: 24,
  medium: 32,
  large: 48,
}

export interface ThinkLifeLogoProps
  extends Except<
    StackProps,
    'direction' | 'spacing' | 'alignItems' | 'justifyContent'
  > {
  size?: keyof typeof sizes
}

export function ThinkLifeLogo({
  size = 'medium',
  ...rest
}: ThinkLifeLogoProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Image
        src="/thinklife-logo-icon.svg"
        alt="ThinkLife Logo Icon"
        height={sizes[size]}
      />
      <Image
        src="/thinklife-logo-text.svg"
        alt="ThinkLife Logo Text"
        height={sizes[size] / 2}
      />
    </Stack>
  )
}
