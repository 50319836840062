import Box, { type BoxProps } from '@mui/material/Box'
import { type Except } from 'type-fest'

export interface ImageProps extends Except<BoxProps, 'component'> {
  src: string
  alt: string
}

export function Image({ src, alt, ...rest }: ImageProps) {
  return <Box component="img" src={src} alt={alt} {...rest} />
}
