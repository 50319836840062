import AssignmentIcon from '@mui/icons-material/AssignmentOutlined'
import InfoIcon from '@mui/icons-material/Info'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const agentsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.agents,
    async () => await import('@/pages/contacts/AgentsPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.agentGeneral.path,
          },
          {
            label: 'Commissions',
            icon: <RequestQuoteIcon />,
            linkTo: routes.agentCommissions.path,
          },
          {
            label: 'Contracting',
            icon: <AssignmentIcon />,
            linkTo: routes.agentContracting.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.agentGeneral,
        async () => await import('@/pages/contacts/AgentGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.agentCommissions,
        async () => await import('@/pages/contacts/CommissionsPage'),
      ),
      createRoleProtectedRouteObject(
        routes.agentContracting,
        async () => await import('@/pages/UnimplementedPage'),
      ),
    ],
  },
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.agentCommissionGeneral.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.agentCommissionGeneral,
        async () => await import('@/pages/contacts/CommissionGeneralPage'),
      ),
    ],
  },
]
