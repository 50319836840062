import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

import { Notifications } from './Notifications'
import { UserMenu } from './UserMenu'

import { ThinkLifeLogo } from '@/components/ThinkLifeLogo'
import { Avatar } from '@/components/berry/Avatar'
import { routes } from '@/configs/routes'

export interface HeaderProps {
  onToggleDrawer: () => void
}

export function Header({ onToggleDrawer }: HeaderProps) {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Box sx={{ width: downMD ? 'auto' : 228, display: 'flex' }}>
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <Link to={routes.dashboard.path}>
            <ThinkLifeLogo />
          </Link>
        </Box>
        <Avatar
          onClick={onToggleDrawer}
          variant="rounded"
          sx={{
            'overflow': 'hidden',
            'transition': 'all .2s ease-in-out',
            'bgcolor':
              theme.palette.mode === 'dark' ? 'dark.main' : 'secondary.light',
            'color':
              theme.palette.mode === 'dark'
                ? 'secondary.main'
                : 'secondary.dark',
            '&:hover': {
              bgcolor:
                theme.palette.mode === 'dark'
                  ? 'secondary.main'
                  : 'secondary.dark',
              color:
                theme.palette.mode === 'dark'
                  ? 'secondary.light'
                  : 'secondary.light',
            },
          }}
          color="inherit"
        >
          <MenuIcon />
        </Avatar>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Notifications />
      <UserMenu />
    </>
  )
}
