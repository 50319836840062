import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { AppCopyright } from './AppCopyright'
import { Breadcrumbs } from './Breadcrumbs'
import { Header } from './Header'
import { SideMenu } from './SideMenu'
import { StyledMain } from './StyledMain'

import { routes } from '@/configs/routes'

export function MainLayout() {
  const [menuOpen, setMenuOpen] = useState(true)
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    downMD && setMenuOpen(false)
  }, [downMD])

  const handleToggleDrawer = useCallback(() => {
    setMenuOpen((currentOpen) => !currentOpen)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setMenuOpen(false)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: 'background.default' }}
      >
        <Toolbar sx={{ p: 2 }}>
          <Header onToggleDrawer={handleToggleDrawer} />
        </Toolbar>
      </AppBar>

      <SideMenu
        onToggle={handleToggleDrawer}
        onClose={handleCloseDrawer}
        open={menuOpen}
      />

      <StyledMain menuOpen={menuOpen}>
        <Container maxWidth={false} sx={{ px: { xs: 0 }, flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs routes={routes} homePath={routes.dashboard.path} />
            </Grid>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>

        <Box flexShrink={0} marginTop={3}>
          <AppCopyright />
        </Box>
      </StyledMain>
    </Box>
  )
}
