import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'

import { Avatar } from '@/components/berry/Avatar'

export interface UserMenuIconProps {
  open: boolean
  onClick: () => void
}

export const UserMenuIcon = forwardRef<HTMLDivElement, UserMenuIconProps>(
  function UserMenuIcon({ open, onClick }, ref) {
    const theme = useTheme()

    return (
      <Chip
        sx={{
          'ml': 2,
          'height': '48px',
          'alignItems': 'center',
          'borderRadius': '27px',
          'transition': 'all .2s ease-in-out',
          'borderColor':
            theme.palette.mode === 'dark' ? 'dark.main' : 'primary.light',
          'bgcolor':
            theme.palette.mode === 'dark' ? 'dark.main' : 'primary.light',
          '&[aria-controls="menu-list-grow"], &:hover': {
            'borderColor': 'primary.main',
            'bgcolor': `${theme.palette.primary.main} !important`,
            'color': 'primary.light',
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            sx={{
              margin: '8px 0 8px 8px !important',
            }}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<SettingsIcon />}
        variant="outlined"
        ref={ref}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={onClick}
        color="primary"
        aria-label="user-account"
      />
    )
  },
)
