import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ButtonBase from '@mui/material/ButtonBase'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { type NavItemType } from './types'
import { urlOrChildUrlMatches } from './utils'

export interface NavItemProps {
  menuOpen: boolean
  item: NavItemType
  level: number
  onMenuClose: () => void
}

export function NavItem({ menuOpen, item, level, onMenuClose }: NavItemProps) {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down('md'))
  const ref = useRef<HTMLSpanElement>(null)

  const { pathname } = useLocation()

  const isSelected = urlOrChildUrlMatches(item, pathname)

  const Icon = item?.icon ?? FiberManualRecordIcon
  const iconSize = item?.icon != null ? 24 : isSelected ? 8 : 6
  const itemIcon = (
    <Icon
      sx={{ width: iconSize, height: iconSize }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  const itemHandler = () => {
    if (downMD) onMenuClose()
  }

  const iconSelectedColor =
    theme.palette.mode === 'dark' && menuOpen
      ? 'text.primary'
      : 'secondary.main'

  return (
    <>
      {
        <ListItemButton
          component={Link}
          to={item.url ?? '#'}
          disableRipple={!menuOpen}
          sx={{
            zIndex: 1201,
            borderRadius: `8px`,
            mb: 0.5,
            pl: menuOpen ? `${level * 24}px` : 1.25,
            ...(menuOpen &&
              level === 1 &&
              theme.palette.mode !== 'dark' && {
                '&:hover': {
                  bgcolor: 'secondary.light',
                },
                '&.Mui-selected': {
                  'bgcolor': 'secondary.light',
                  'color': iconSelectedColor,
                  '&:hover': {
                    color: iconSelectedColor,
                    bgcolor: 'secondary.light',
                  },
                },
              }),
            ...((!menuOpen || level !== 1) && {
              'py': level === 1 ? 0 : 1,
              '&:hover': {
                bgcolor: 'transparent',
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: 'transparent',
                },
                'bgcolor': 'transparent',
              },
            }),
          }}
          selected={isSelected}
          onClick={() => {
            itemHandler()
          }}
        >
          <ButtonBase
            aria-label="theme-icon"
            sx={{ borderRadius: `8px` }}
            disableRipple={menuOpen}
          >
            <ListItemIcon
              sx={{
                minWidth: level === 1 ? 36 : 18,
                color: isSelected ? iconSelectedColor : 'text.primary',
                ...(!menuOpen &&
                  level === 1 && {
                    'borderRadius': `8px`,
                    'width': 46,
                    'height': 46,
                    'alignItems': 'center',
                    'justifyContent': 'center',
                    '&:hover': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? alpha(theme.palette.secondary.main, 0.25)
                          : 'secondary.light',
                    },
                    ...(isSelected && {
                      'bgcolor':
                        theme.palette.mode === 'dark'
                          ? alpha(theme.palette.secondary.main, 0.25)
                          : 'secondary.light',
                      '&:hover': {
                        bgcolor:
                          theme.palette.mode === 'dark'
                            ? alpha(theme.palette.secondary.main, 0.3)
                            : 'secondary.light',
                      },
                    }),
                  }),
              }}
            >
              {menuOpen ? (
                itemIcon
              ) : (
                <Tooltip title={item.title} placement="right">
                  {itemIcon}
                </Tooltip>
              )}
            </ListItemIcon>
          </ButtonBase>

          {(menuOpen || level !== 1) && (
            <ListItemText
              primary={
                <Typography
                  ref={ref}
                  noWrap
                  variant={isSelected ? 'h5' : 'body1'}
                  color="inherit"
                >
                  {item.title}
                </Typography>
              }
            />
          )}
        </ListItemButton>
      }
    </>
  )
}
