import { type RouteObject } from 'react-router-dom'

import { AuthProtectedRoute } from '../helpers/AuthProtectedRoute'
import { createRoleProtectedRouteObject } from '../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { AuthLayout } from '@/layouts'
import {
  userLoggedIn,
  userRequiresApplication,
  userWaitingOnApplication,
} from '@/lib/loginFlowHelpers'

export const authRoutes: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        // User is signed out
        element: <AuthProtectedRoute allowIf={(auth) => !userLoggedIn(auth)} />,
        children: [
          createRoleProtectedRouteObject(
            routes.signIn,
            async () => await import('@/pages/auth/SignIn'),
          ),
          createRoleProtectedRouteObject(
            routes.signUp,
            async () => await import('@/pages/auth/SignUp'),
          ),
        ],
      },
      {
        element: (
          <AuthProtectedRoute
            allowIf={(auth) => userRequiresApplication(auth)}
          />
        ),
        children: [
          createRoleProtectedRouteObject(
            routes.createProfile,
            async () => await import('@/pages/auth/CreateProfile'),
          ),
        ],
      },
      {
        element: (
          <AuthProtectedRoute
            allowIf={(auth) => userWaitingOnApplication(auth)}
          />
        ),
        children: [
          createRoleProtectedRouteObject(
            routes.applicationPending,
            async () => await import('@/pages/auth/ApplicationPending'),
          ),
        ],
      },
      createRoleProtectedRouteObject(
        routes.acceptInvite,
        async () => await import('@/pages/auth/AcceptInvitePage'),
      ),
      createRoleProtectedRouteObject(
        routes.forgotPassword,
        async () => await import('@/pages/auth/ForgotPassword'),
      ),
    ],
  },
]
