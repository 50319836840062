import { useCallback, useRef, useState } from 'react'

import { UserMenuIcon } from './UserMenuIcon'
import { UserMenuPopper } from './UserMenuPopper'

export function UserMenu() {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  return (
    <>
      <UserMenuIcon ref={anchorRef} open={open} onClick={handleToggle} />
      <UserMenuPopper
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}
