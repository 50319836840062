import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'

const lightGradient =
  'linear-gradient(20deg, rgba(245,245,245,1) 0%, rgba(249,224,255,1) 80%, rgba(229,128,255,1) 100%);'
const darkGradient =
  'linear-gradient(20deg, rgba(10,10,10,1) 0%, rgba(51,0,64,1) 75%, rgba(102,0,128,1) 100%);'

export function BasicLayout() {
  return (
    <Box
      sx={{
        background: (theme) =>
          theme.palette.mode === 'dark' ? darkGradient : lightGradient,
        minHeight: '100vh',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            margin: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 },
            padding: { xs: 4, md: 8, xl: 10 },
            borderRadius: 8,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
