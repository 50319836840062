import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ArticleIcon from '@mui/icons-material/Article'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import ContactsIcon from '@mui/icons-material/Contacts'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DescriptionIcon from '@mui/icons-material/Description'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import CalendarIcon from '@mui/icons-material/Event'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import LeadsIcon from '@mui/icons-material/PersonSearch'
import SalesIcon from '@mui/icons-material/PointOfSale'

import { type NavItemType } from './SideMenu/types'

import { routes } from '@/configs/routes'

export const GRID_SPACING = 3
export const DRAWER_WIDTH = 260

export const MENU_ITEMS: NavItemType[] = [
  {
    id: 'dashboard',
    title: routes.dashboard.name,
    type: 'item',
    url: routes.dashboard.path,
    icon: DashboardIcon,
    allowedRoles: routes.dashboard.allowedRoles,
  },
  {
    id: 'newBusinesses',
    title: routes.newBusinesses.name,
    type: 'item',
    url: routes.newBusinesses.path,
    icon: BusinessCenterIcon,
    allowedRoles: routes.newBusinesses.allowedRoles,
  },
  {
    id: 'inForcePolicies',
    title: routes.inForcePolicies.name,
    type: 'item',
    url: routes.inForcePolicies.path,
    icon: ArticleIcon,
    allowedRoles: routes.inForcePolicies.allowedRoles,
  },
  {
    id: 'reports',
    title: 'Reports',
    type: 'collapse',
    icon: DescriptionIcon,
    allowedRoles: ['admin', 'agencyAdmin', 'agent'],
    children: [
      {
        id: 'inForceReport',
        title: routes.inForceReport.name,
        type: 'item',
        url: routes.inForceReport.path,
        allowedRoles: routes.inForceReport.allowedRoles,
      },
      {
        id: 'pendingReport',
        title: routes.pendingReport.name,
        type: 'item',
        url: routes.pendingReport.path,
        allowedRoles: routes.pendingReport.allowedRoles,
      },
      {
        id: 'submittedReport',
        title: routes.submittedReport.name,
        type: 'item',
        url: routes.submittedReport.path,
        allowedRoles: routes.submittedReport.allowedRoles,
      },
      {
        id: 'earningsReport',
        title: routes.earningsReport.name,
        type: 'item',
        url: routes.earningsReport.path,
        allowedRoles: routes.earningsReport.allowedRoles,
      },
      {
        id: 'commissionsReport',
        title: routes.commissionsReport.name,
        type: 'item',
        url: routes.commissionsReport.path,
        allowedRoles: routes.commissionsReport.allowedRoles,
      },
      {
        id: 'comparisonReport',
        title: routes.comparisonReport.name,
        type: 'item',
        url: routes.comparisonReport.path,
        allowedRoles: routes.comparisonReport.allowedRoles,
      },
      {
        id: 'hierarchy',
        title: routes.hierarchy.name,
        type: 'item',
        url: routes.hierarchy.path,
        allowedRoles: routes.hierarchy.allowedRoles,
      },
      {
        id: 'leadDetails',
        title: routes.leadDetails.name,
        type: 'item',
        url: routes.leadDetails.path,
        allowedRoles: routes.leadDetails.allowedRoles,
      },
      {
        id: 'publisherReport',
        title: routes.publisherReport.name,
        type: 'item',
        url: routes.publisherReport.path,
        allowedRoles: routes.publisherReport.allowedRoles,
      },
    ],
  },
  {
    id: 'contacts',
    title: 'Contacts',
    type: 'collapse',
    icon: ContactsIcon,
    children: [
      {
        id: 'agencies',
        title: routes.agencies.name,
        type: 'item',
        url: routes.agencies.path,
        allowedRoles: routes.agencies.allowedRoles,
      },
      {
        id: 'agents',
        title: routes.agents.name,
        type: 'item',
        url: routes.agents.path,
        allowedRoles: routes.agents.allowedRoles,
      },
      {
        id: 'customers',
        title: routes.customers.name,
        type: 'item',
        url: routes.customers.path,
        allowedRoles: routes.customers.allowedRoles,
      },
      {
        id: 'invitations',
        title: routes.invitations.name,
        type: 'item',
        url: routes.invitations.path,
        allowedRoles: routes.invitations.allowedRoles,
      },
    ],
  },
  {
    id: 'calendar',
    title: 'Calendar',
    type: 'collapse',
    icon: CalendarIcon,
    allowedRoles: ['admin'],
    children: [
      {
        id: 'myCalendar',
        title: routes.myCalendar.name,
        type: 'item',
        url: routes.myCalendar.path,
        allowedRoles: routes.myCalendar.allowedRoles,
      },
      {
        id: 'tasks',
        title: routes.tasks.name,
        type: 'item',
        url: routes.tasks.path,
        allowedRoles: routes.tasks.allowedRoles,
      },
      {
        id: 'reminders',
        title: routes.reminders.name,
        type: 'item',
        url: routes.reminders.path,
        allowedRoles: routes.reminders.allowedRoles,
      },
    ],
  },
  {
    id: 'sales',
    title: 'Sales',
    type: 'collapse',
    icon: SalesIcon,
    allowedRoles: ['admin'],
    children: [
      {
        id: 'prospects',
        title: routes.prospects.name,
        type: 'item',
        url: routes.prospects.path,
        allowedRoles: routes.prospects.allowedRoles,
      },
      {
        id: 'pipeline',
        title: routes.pipeline.name,
        type: 'item',
        url: routes.pipeline.path,
        allowedRoles: routes.pipeline.allowedRoles,
      },
      {
        id: 'quotes',
        title: routes.quotes.name,
        type: 'item',
        url: routes.quotes.path,
        allowedRoles: routes.quotes.allowedRoles,
      },
      {
        id: 'applications',
        title: routes.applications.name,
        type: 'item',
        url: routes.applications.path,
        allowedRoles: routes.applications.allowedRoles,
      },
    ],
  },
  {
    id: 'marketing',
    title: 'Marketing',
    type: 'collapse',
    icon: MarkEmailReadIcon,
    allowedRoles: ['admin'],
    children: [
      {
        id: 'marketingCampaigns',
        title: routes.marketingCampaigns.name,
        type: 'item',
        url: routes.marketingCampaigns.path,
        allowedRoles: routes.marketingCampaigns.allowedRoles,
      },
      {
        id: 'website',
        title: routes.website.name,
        type: 'item',
        url: routes.website.path,
        allowedRoles: routes.website.allowedRoles,
      },
      {
        id: 'marketingContacts',
        title: routes.marketingContacts.name,
        type: 'item',
        url: routes.marketingContacts.path,
        allowedRoles: routes.marketingContacts.allowedRoles,
      },
      {
        id: 'emailTemplates',
        title: routes.emailTemplates.name,
        type: 'item',
        url: routes.emailTemplates.path,
        allowedRoles: routes.emailTemplates.allowedRoles,
      },
    ],
  },
  {
    id: 'leadManagement',
    title: 'Lead Management',
    type: 'collapse',
    icon: LeadsIcon,
    children: [
      {
        id: 'leadCampaigns',
        title: routes.leadCampaigns.name,
        type: 'item',
        url: routes.leadCampaigns.path,
        allowedRoles: routes.leadCampaigns.allowedRoles,
      },
      {
        id: 'publishers',
        title: routes.publishers.name,
        type: 'item',
        url: routes.publishers.path,
        allowedRoles: routes.publishers.allowedRoles,
      },
      {
        id: 'channels',
        title: routes.channels.name,
        type: 'item',
        url: routes.channels.path,
        allowedRoles: routes.channels.allowedRoles,
      },
    ],
  },
  {
    id: 'systemManagement',
    title: 'System Management',
    type: 'collapse',
    icon: DisplaySettingsIcon,
    allowedRoles: ['admin'],
    children: [
      {
        id: 'users',
        title: routes.users.name,
        type: 'item',
        url: routes.users.path,
        allowedRoles: routes.users.allowedRoles,
      },
      {
        id: 'carriers',
        title: routes.carriers.name,
        type: 'item',
        url: routes.carriers.path,
        allowedRoles: routes.carriers.allowedRoles,
      },
      {
        id: 'pendingUsers',
        title: routes.pendingUsers.name,
        type: 'item',
        url: routes.pendingUsers.path,
        allowedRoles: routes.pendingUsers.allowedRoles,
      },
      {
        id: 'commissions',
        title: routes.commissions.name,
        type: 'item',
        url: routes.commissions.path,
        allowedRoles: routes.commissions.allowedRoles,
      },
      {
        id: 'knowledgeBase',
        title: 'Knowledge Base',
        type: 'item',
      },
    ],
  },
  {
    id: 'integrations',
    title: routes.integrations.name,
    type: 'item',
    url: routes.integrations.path,
    icon: IntegrationInstructionsIcon,
    allowedRoles: routes.integrations.allowedRoles,
  },
  {
    id: 'administration',
    title: routes.administration.name,
    type: 'item',
    url: routes.administration.path,
    icon: AdminPanelSettingsIcon,
    allowedRoles: routes.administration.allowedRoles,
  },
]
