import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'

export const reportsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.inForceReport,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.pendingReport,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.submittedReport,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.earningsReport,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.commissionsReport,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.comparisonReport,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.hierarchy,
    async () => await import('@/pages/UnimplementedPage'),
  ),
  createRoleProtectedRouteObject(
    routes.publisherReport,
    async () => await import('@/pages/reports/PublisherReportPage'),
  ),
  createRoleProtectedRouteObject(
    routes.leadDetails,
    async () => await import('@/pages/reports/LeadDetailsPage'),
  ),
]
