import { styled } from '@mui/material/styles'

import { DRAWER_WIDTH } from './constants'

interface StyledMainProps {
  menuOpen: boolean
}

export const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'menuOpen',
})<StyledMainProps>(({ theme, menuOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.dark[800]
      : theme.palette.grey[100],
  minWidth: '1%',
  width: '100%',
  minHeight: 'calc(100vh - 88px)',
  flexGrow: 1,
  padding: 20,
  marginTop: 88,
  marginRight: 20,
  borderRadius: `8px`,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  ...(!menuOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter + 200,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(DRAWER_WIDTH - 72),
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginTop: 88,
    },
  }),
  ...(menuOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter + 200,
    }),
    marginLeft: 0,
    marginTop: 88,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    [theme.breakpoints.up('md')]: {
      marginTop: 88,
    },
  }),
  [theme.breakpoints.down('md')]: {
    marginLeft: 20,
    padding: 16,
    marginTop: 88,
    ...(!menuOpen && {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    }),
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 10,
    marginRight: 10,
  },
}))
