import InfoIcon from '@mui/icons-material/Info'
import MonetizationIcon from '@mui/icons-material/MonetizationOn'
import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const publishersRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.publishers,
    async () => await import('@/pages/leadManagement/PublishersPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.publisherGeneral.path,
          },
          {
            label: 'Rev Share',
            icon: <MonetizationIcon />,
            linkTo: routes.publisherRevShare.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.publisherGeneral,
        async () => await import('@/pages/leadManagement/PublisherGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.publisherRevShare,
        async () =>
          await import('@/pages/leadManagement/PublisherRevSharePage'),
      ),
    ],
  },
]
