import NotificationsIcon from '@mui/icons-material/Notifications'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

import { Avatar } from '@/components/berry/Avatar'

export function Notifications() {
  const theme = useTheme()

  return (
    <Box sx={{ ml: 2 }}>
      <Avatar
        variant="rounded"
        sx={{
          'transition': 'all .2s ease-in-out',
          'bgcolor':
            theme.palette.mode === 'dark' ? 'dark.main' : 'secondary.light',
          'color':
            theme.palette.mode === 'dark' ? 'warning.dark' : 'secondary.dark',
          '&[aria-controls="menu-list-grow"],&:hover': {
            bgcolor:
              theme.palette.mode === 'dark' ? 'warning.dark' : 'secondary.dark',
            color:
              theme.palette.mode === 'dark' ? 'grey.800' : 'secondary.light',
          },
        }}
        color="inherit"
      >
        <NotificationsIcon fontSize="small" />
      </Avatar>
    </Box>
  )
}
