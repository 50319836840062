import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { MENU_ITEMS } from '../constants'

import { NavCollapse } from './NavCollapse'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'

import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export interface MenuListProps {
  open: boolean
  onClose: () => void
}

export function MenuList({ open, onClose }: MenuListProps) {
  const { idTokenResult } = useAuthentication()
  const userRoles = idTokenResult?.claims.roles ?? []

  const navItems = MENU_ITEMS.map((item) => {
    const dontShow =
      item.allowedRoles?.every((itemRole) => !userRoles.includes(itemRole)) ===
      true

    if (dontShow) {
      return undefined
    }

    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            menuOpen={open}
            onMenuClose={onClose}
            key={item.id}
            item={item}
          />
        )
      case 'item':
        return (
          <NavItem
            key={item.id}
            item={item}
            level={1}
            menuOpen={open}
            onMenuClose={onClose}
          />
        )
      case 'collapse':
        return (
          <NavCollapse
            key={item.id}
            menuOpen={open}
            onMenuClose={onClose}
            item={item}
            level={1}
          />
        )
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return <Box {...(open && { sx: { mt: 1.5 } })}>{navItems}</Box>
}
