import { ApolloProvider } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { RouterProvider } from 'react-router-dom'

import { graphqlClient } from './configs/graphql'
import { AuthenticationProvider } from './providers/AuthenticationProvider/AuthenticationProvider'
import { ThemeConfigProvider } from './providers/ThemeConfigProvider/ThemeConfigProvider'
import { router } from './router'

export function App() {
  return (
    <ThemeConfigProvider>
      <ApolloProvider client={graphqlClient}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
          <SnackbarProvider
            action={(snackbarId) => (
              <IconButton
                onClick={() => {
                  closeSnackbar(snackbarId)
                }}
                size="large"
                aria-label="close modal"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          >
            <AuthenticationProvider>
              <RouterProvider router={router} />
            </AuthenticationProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeConfigProvider>
  )
}
