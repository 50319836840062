import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { type Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'

import { DRAWER_WIDTH } from '../constants'

import { MenuList } from './MenuList'
import { MiniDrawer } from './MiniDrawer'

import { ThinkLifeLogo } from '@/components/ThinkLifeLogo'

export interface SideMenuProps {
  open: boolean
  onToggle: () => void
  onClose: () => void
}

export function SideMenu({ open, onToggle, onClose }: SideMenuProps) {
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const logo = useMemo(
    () => (
      <Box sx={{ display: 'flex', p: 2 }}>
        <ThinkLifeLogo />
      </Box>
    ),
    [],
  )

  const drawer = useMemo(() => {
    const drawerSX = open
      ? {
          paddingLeft: '16px',
          paddingRight: '16px',
          marginTop: '0px',
        }
      : {
          paddingLeft: '0px',
          paddingRight: '0px',
          marginTop: '20px',
        }

    return (
      <>
        {downMD ? (
          <Box sx={drawerSX}>
            <MenuList open={open} onClose={onClose} />
          </Box>
        ) : (
          <Box paddingX={open ? 2 : 0} paddingY={2}>
            <MenuList open={open} onClose={onClose} />
          </Box>
        )}
      </>
    )
  }, [downMD, onClose, open])

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: { xs: 'auto', md: DRAWER_WIDTH } }}
      aria-label="mailbox folders"
    >
      {downMD ? (
        <Drawer
          variant={downMD ? 'temporary' : 'persistent'}
          anchor="left"
          open={open}
          onClose={onToggle}
          sx={{
            '& .MuiDrawer-paper': {
              mt: downMD ? 0 : 11,
              zIndex: 1099,
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              color: 'text.primary',
              borderRight: 'none',
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          {downMD && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawer variant="permanent" open={open}>
          {logo}
          {drawer}
        </MiniDrawer>
      )}
    </Box>
  )
}
