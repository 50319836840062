import { useContext } from 'react'

import { AuthenticationContext } from './AuthenticationContext'
import { type AuthenticationContextValue } from './types'

export function useAuthentication(): AuthenticationContextValue {
  const auth = useContext(AuthenticationContext)

  if (auth == null) {
    throw new Error('useAuth was used outside of an AuthProvider')
  }

  return auth
}
