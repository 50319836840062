import MuiAvatar, {
  type AvatarProps as MuiAvatarProps,
} from '@mui/material/Avatar'

const sizes = {
  sm: { boxSize: 22, fontSize: '1rem' },
  md: { boxSize: 34, fontSize: '1.2rem' },
  lg: { boxSize: 44, fontSize: '1.5rem' },
  xl: { boxSize: 64, fontSize: '2rem' },
}

export interface AvatarProps extends MuiAvatarProps {
  size?: keyof typeof sizes
}

export function Avatar({ size = 'md', sx, variant, ...rest }: AvatarProps) {
  const { boxSize, fontSize } = sizes[size]
  return (
    <MuiAvatar
      variant={variant}
      sx={{
        height: boxSize,
        width: boxSize,
        fontSize,
        cursor: 'pointer',
        ...(variant === 'rounded' && { borderRadius: '8px' }),
        ...sx,
      }}
      {...rest}
    />
  )
}
