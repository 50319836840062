import FilterAltIcon from '@mui/icons-material/FilterAlt'
import InfoIcon from '@mui/icons-material/Info'
import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const leadCampaignsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.leadCampaigns,
    async () => await import('@/pages/leadManagement/CampaignsPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.leadCampaignGeneral.path,
          },
          {
            label: 'Filters',
            icon: <FilterAltIcon />,
            linkTo: routes.leadCampaignFilters.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.leadCampaignGeneral,
        async () => await import('@/pages/leadManagement/CampaignGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.leadCampaignFilters,
        async () => await import('@/pages/leadManagement/CampaignFiltersPage'),
      ),
    ],
  },
]
