import ReceiptIcon from '@mui/icons-material/Receipt'
import SecurityIcon from '@mui/icons-material/Security'
import { type RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { AccountSettingsLayout } from '@/layouts'

export const accountSettingsRoutes: RouteObject[] = [
  {
    element: (
      <AccountSettingsLayout
        tabs={[
          {
            label: 'Security',
            icon: <SecurityIcon />,
            linkTo: routes.accountSecurity.path,
          },
          {
            label: 'Credit History',
            icon: <ReceiptIcon />,
            linkTo: routes.accountCreditHistory.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.accountSecurity,
        async () => await import('@/pages/accountSettings/AccountSecurityPage'),
      ),
      createRoleProtectedRouteObject(
        routes.accountCreditHistory,
        async () => await import('@/pages/accountSettings/CreditHistoryPage'),
      ),
    ],
  },
]
