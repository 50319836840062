import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import { Link } from '@/components/Link'
import { routes } from '@/configs/routes'

export function ErrorPage() {
  const error = useRouteError()

  let message: string
  let stackTrace: string[] | undefined
  if (isRouteErrorResponse(error)) {
    message = `${error.status}: ${error.statusText}`
  } else if (error instanceof Error) {
    message = `${error.name}: ${error.message}`
    stackTrace = error.stack?.split('\n')
  } else {
    message = 'Unknown error'
  }

  return (
    <Container>
      <Box padding={4} display="flex" flexDirection="column">
        <Typography variant="h2" textAlign="center">
          Error
        </Typography>
        <Box paddingY={4}>
          <Typography variant="body1" textAlign="center">
            {message}
          </Typography>
        </Box>

        {import.meta.env.DEV &&
          stackTrace?.map((stackTraceLine) => (
            <Box paddingBottom={1}>
              <Typography variant="caption">{stackTraceLine}</Typography>
            </Box>
          ))}

        <Link to={routes.dashboard.path} textAlign="center">
          Return to main page
        </Link>
      </Box>
    </Container>
  )
}
