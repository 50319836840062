import { type RouteObject } from 'react-router-dom'

import { leadCampaignsRoutes } from './campaigns'
import { channelsRoutes } from './channels'
import { publishersRoutes } from './publishers'

export const leadManagementRoutes: RouteObject[] = [
  ...leadCampaignsRoutes,
  ...publishersRoutes,
  ...channelsRoutes,
]
