import {
  type FirebaseApp,
  type FirebaseOptions,
  initializeApp,
} from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'

function getFirebaseApp(): FirebaseApp | undefined {
  const firebaseConfigBase64 = import.meta.env.VITE_FIREBASE_CONFIG_BASE64

  if (firebaseConfigBase64 == null) {
    return undefined
  }

  const firebaseConfig = JSON.parse(
    atob(firebaseConfigBase64),
  ) as FirebaseOptions

  return initializeApp(firebaseConfig)
}

const app = getFirebaseApp()

export const firebaseAuth = getAuth(app)

if (import.meta.env.VITE_FIREBASE_EMULATORS === 'true') {
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
}
