import { matchPath } from 'react-router-dom'

import { type NavItemType } from './types'

export function urlOrChildUrlMatches(
  { url, children }: NavItemType,
  urlToMatch: string,
): boolean {
  if (url != null && matchPath({ path: url, end: false }, urlToMatch) != null) {
    return true
  }
  if (children != null) {
    for (const child of children) {
      if (urlOrChildUrlMatches(child, urlToMatch)) {
        return true
      }
    }
  }
  return false
}
