import { Outlet } from 'react-router-dom'

import { type UserRole } from '@/providers/AuthenticationProvider/types'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export interface RoleProtectedRouteProps {
  allowedRoles?: UserRole[]
}

export function RoleProtectedRoute({ allowedRoles }: RoleProtectedRouteProps) {
  const { idTokenResult } = useAuthentication()
  const userRoles = idTokenResult?.claims.roles ?? []

  const allowed =
    allowedRoles == null ||
    allowedRoles.some((allowedRole) => userRoles.includes(allowedRole))

  if (!allowed) {
    throw new NotFoundError()
  }

  return <Outlet />
}

class NotFoundError extends Error {
  name = '404'
  message = 'Not Found'
}
