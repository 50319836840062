import { useMemo } from 'react'
import { Navigate, type To } from 'react-router-dom'

import { routes } from '@/configs/routes'
import {
  userHasRoles,
  userLoggedIn,
  userRequiresApplication,
  userWaitingOnApplication,
} from '@/lib/loginFlowHelpers'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export function AuthRedirector() {
  const auth = useAuthentication()

  const redirectTo: To = useMemo(() => {
    if (!userLoggedIn(auth)) {
      return routes.signIn.path
    }

    if (userRequiresApplication(auth)) {
      return routes.createProfile.path
    }

    if (userWaitingOnApplication(auth)) {
      return routes.applicationPending.path
    }

    if (userHasRoles(auth)) {
      return routes.dashboard.path
    }

    throw new Error('Unexpected user state')
  }, [auth])

  return <Navigate replace to={redirectTo} />
}
