import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export interface LoadingSpinnerProps {
  size?: number
}

export function LoadingSpinner({ size }: LoadingSpinnerProps) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={size} />
    </Box>
  )
}
