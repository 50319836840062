import { useContext } from 'react'

import { ThemeConfigContext } from './ThemeConfigContext'
import { type ThemeConfigContextValue } from './types'

export function useThemeConfig(): ThemeConfigContextValue {
  const themeConfig = useContext(ThemeConfigContext)

  if (themeConfig == null) {
    throw new Error('useThemeConfig was used outside of an ThemeConfigProvider')
  }

  return themeConfig
}
