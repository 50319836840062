import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { NavItem } from './NavItem'
import { type NavItemType } from './types'
import { urlOrChildUrlMatches } from './utils'

import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

interface VirtualElement {
  getBoundingClientRect: () => DOMRectReadOnly | DOMRect
  contextElement?: Element
}

interface NavCollapseProps {
  menuOpen: boolean
  onMenuClose: () => void
  item: NavItemType
  level: number
}

export function NavCollapse({
  menuOpen,
  onMenuClose,
  item,
  level,
}: NavCollapseProps) {
  const { idTokenResult } = useAuthentication()
  const userRoles = idTokenResult?.claims.roles ?? []

  const theme = useTheme()
  const ref = useRef<HTMLSpanElement>(null)

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
  >(null)

  const handleClickMini = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined,
  ) => {
    setAnchorEl(null)
    if (menuOpen) {
      setOpen(!open)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const openMini = Boolean(anchorEl)

  const handleClosePopper = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const { pathname } = useLocation()

  const checkOpenForParent = (child: NavItemType[]) => {
    child.forEach((item: NavItemType) => {
      if (item.url === pathname) {
        setOpen(true)
      }
    })
  }

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false)
    openMini && setAnchorEl(null)
    if (item.children != null) {
      item.children.forEach((item: NavItemType) => {
        if (item.children?.length != null && item.children.length !== 0) {
          checkOpenForParent(item.children)
        }
        if (item.url === pathname) {
          setOpen(true)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, item.children])

  const [hoverStatus, setHover] = useState<boolean>(false)

  const compareSize = () => {
    const compare =
      ref.current != null && ref.current.scrollWidth > ref.current.clientWidth
    setHover(compare)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
    window.removeEventListener('resize', compareSize)
  }, [])

  useEffect(() => {
    if (item.url === pathname) {
      setAnchorEl(null)
      setOpen(true)
    }
  }, [pathname, item])

  // menu collapse & item
  const menus = item.children?.map((item) => {
    const dontShow =
      item.allowedRoles?.every((itemRole) => !userRoles.includes(itemRole)) ===
      true

    if (dontShow) {
      return undefined
    }

    switch (item.type) {
      case 'collapse':
        return (
          <NavCollapse
            menuOpen={menuOpen}
            onMenuClose={onMenuClose}
            key={item.id}
            item={item}
            level={level + 1}
          />
        )
      case 'item':
        return (
          <NavItem
            menuOpen={menuOpen}
            onMenuClose={onMenuClose}
            key={item.id}
            item={item}
            level={level + 1}
          />
        )
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  const isSelected = urlOrChildUrlMatches(item, pathname)

  const Icon = item.icon ?? FiberManualRecordIcon
  const menuIcon = (
    <Icon
      sx={{ width: 24, height: 24 }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  const collapseIcon = menuOpen ? (
    <ChevronUpIcon
      sx={{ height: 16, width: 16, marginTop: 'auto', marginBottom: 'auto' }}
    />
  ) : (
    <ChevronRightIcon
      sx={{ height: 16, width: 16, marginTop: 'auto', marginBottom: 'auto' }}
    />
  )

  const iconSelectedColor =
    theme.palette.mode === 'dark' && menuOpen
      ? 'text.primary'
      : 'secondary.main'

  return (
    <>
      <ListItemButton
        sx={{
          zIndex: 1201,
          borderRadius: `8px`,
          mb: 0.5,
          pl: menuOpen ? `${level * 24}px` : 1.25,
          ...(menuOpen &&
            level === 1 &&
            theme.palette.mode !== 'dark' && {
              '&:hover': { bgcolor: 'secondary.light' },
              '&.Mui-selected': {
                'bgcolor': 'secondary.light',
                'color': iconSelectedColor,
                '&:hover': {
                  color: iconSelectedColor,
                  bgcolor: '.secondary.light',
                },
              },
            }),
          ...((!menuOpen || level !== 1) && {
            'py': level === 1 ? 0 : 1,
            '&:hover': { bgcolor: 'transparent' },
            '&.Mui-selected': {
              '&:hover': { bgcolor: 'transparent' },
              'bgcolor': 'transparent',
            },
          }),
        }}
        selected={isSelected}
        {...(!menuOpen && {
          onMouseEnter: handleClickMini,
          onMouseLeave: handleClosePopper,
        })}
        onClick={handleClickMini}
      >
        {menuIcon != null && (
          <ListItemIcon
            sx={{
              minWidth: level === 1 ? 36 : 18,
              color: isSelected ? iconSelectedColor : 'text.primary',
              ...(!menuOpen &&
                level === 1 && {
                  'borderRadius': `8px`,
                  'width': 46,
                  'height': 46,
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  '&:hover': {
                    bgcolor:
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.secondary.main, 0.25)
                        : 'secondary.light',
                  },
                  ...(isSelected && {
                    'bgcolor':
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.secondary.main, 0.25)
                        : 'secondary.light',
                    '&:hover': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? alpha(theme.palette.secondary.main, 0.3)
                          : 'secondary.light',
                    },
                  }),
                }),
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        {(menuOpen || (!menuOpen && level !== 1)) && (
          <Tooltip title={item.title} disableHoverListener={!hoverStatus}>
            <ListItemText
              primary={
                <Typography
                  ref={ref}
                  noWrap
                  overflow="hidden"
                  textOverflow="ellipsis"
                  variant={isSelected ? 'h5' : 'body1'}
                  color="inherit"
                  width={130}
                >
                  {item.title}
                </Typography>
              }
            />
          </Tooltip>
        )}

        {openMini || open ? (
          collapseIcon
        ) : (
          <ChevronDownIcon
            sx={{
              height: 16,
              width: 16,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
        )}

        {!menuOpen && (
          <Popper
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [-12, 0],
                },
              },
            ]}
            sx={{
              'overflow': 'visible',
              'zIndex': 2001,
              'minWidth': 180,
              '&:before': {
                content: '""',
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 120,
                borderLeft: `1px solid`,
                borderBottom: `1px solid`,
                borderColor: 'divider',
              },
            }}
          >
            {({ TransitionProps }) => (
              <Collapse in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.shadows[8],
                    backgroundImage: 'none',
                  }}
                >
                  <ClickAwayListener onClickAway={handleClosePopper}>
                    <Box>{menus}</Box>
                  </ClickAwayListener>
                </Paper>
              </Collapse>
            )}
          </Popper>
        )}
      </ListItemButton>
      {menuOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {open && (
            <List
              disablePadding
              sx={{
                'position': 'relative',
                '&:after': {
                  content: "''",
                  position: 'absolute',
                  left: '32px',
                  top: 0,
                  height: '100%',
                  width: '1px',
                  opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? 'dark.light'
                      : 'primary.light',
                },
              }}
            >
              {menus}
            </List>
          )}
        </Collapse>
      )}
    </>
  )
}
